.form-container {
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 4vh;
  overflow: visible;
}

.notification {
  color: #00213b;
  display: flex;
  bottom: -10px;
  position: absolute;
  border-radius: 8px;
  padding: 8px 16px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  background-color: white;
  transition: all 0.5s ease;
  animation: notification-apparition 0.3s ease;
}

@keyframes notification-apparition {
  from {
    transform: translateX(-8px);
  }
}

.userbox {
  overflow: visible;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.userbox > input,
.userbox select {
  font-size: 16px;
  width: 80%;
  height: 33px;
  border-radius: 0;
  border: none;
  background: transparent;
  outline: none;
  border-bottom: rgba(0, 0, 0, 0.5) solid 1px;
}

.phone-number {
  width: 80%;
  height: 30px;
  border: none;
  background: transparent;
  outline: none;
  border-bottom: rgba(0, 0, 0, 0.5) solid 1px;
}
.phone-number input {
  background: transparent;
  outline: none;
  border: none;
}

.userbox textarea {
  padding-top: 30px;
  height: 140px;
  width: 80%;
  border: none;
  background: transparent;
  outline: none;
  resize: none;
  border: rgba(0, 0, 0, 0.5) solid 1px;
}
.animate-label {
  position: absolute;
  top: 0;
  pointer-events: none;
  transition: 0.5s;
}

.userbox input:focus ~ .animate-label,
.userbox textarea:focus ~ .animate-label,
.PhoneInput--focus ~ .animate-label {
  transform: translateY(-20px);
  color: #17a8e3;
}
.fixed-label {
  position: absolute;
  top: -20px;
  color: #005273;
  transition: none;
}

.userbox .length {
  position: absolute;
  right: 65px;
  bottom: -25px;
}

.btn-contact {
  font-weight: 700;
  color: white;
  background: linear-gradient(to left, #eaa6a6, #efda66);

  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.btn-contact:hover {
  background: linear-gradient(90deg, #b5aee6 10%, #ecb3d8 90%);
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 500px) {
  .custom-select {
    width: 80%;
  }
  .custom-select >*{
    font-size: 0.7rem;
  }
  .form-container label{
    font-size: 0.7rem;
  }
}

@media (max-width:380px){
  .phone-label{
    transform: translateY(-12px);
  }
  .userbox > input, .userbox select {
    font-size: 13px;
  
}
.faq-section-container{
  width:86%;
}
}
@media (max-width: 320px) {
  .userbox > input, .userbox select {
    font-size: 11px;
  
}
.faq-section-container{
  width:95%;
}

}