.layout{
    isolation: isolate;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-top: 100px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10rem;
    position: relative;
    background-color: transparent;
    width: 70%;
    max-width: 1400px;
}

.validation-container{
   /*background-color: yellow;*/
    width: 100%;
    padding: 2rem;
    /*height: fit-content;*/
}
.form .content-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 100%;
    height: 100%;
}
.link{
    display: flex;
    align-items: center;
    gap: .5rem;
    text-decoration: none;
    color: #00213B;
    font-weight: 600;
    margin-top: 3rem;
    font-size: 1.3rem;
}
.icon {
    display: inline-block;
    animation: moveRightToLeft 0.7s infinite alternate;
}
.form .content-container .share{
    border-radius: 10px;
    background-color: rgba(244, 244, 244, 0.77);
    padding: 2rem;
}
.form .content-container .info{
    grid-column: span 2;
}
.form .content-container .commande {
    grid-column: span 1;
}
@media (max-width: 768px) {
    .layout {
        width: 100%;
        margin-bottom: 3rem;
        padding-top: 2rem;
    }
}
@media (max-width: 1200px) {
    .layout {
        width: 100%;
        margin-bottom: 0;
        padding-top: 2rem;
    }
}
@media (max-width: 768px) {
    .form .content-container  {
        grid-template-columns: 1fr;
        gap: 3rem
    }
    .form .content-container .commande {
        grid-column: span 2;
    }
}
.shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 -4px 8px rgba(0, 0, 0, 0.05), 4px 0 8px rgba(0, 0, 0, 0.05), -4px 0 8px rgba(0, 0, 0, 0.05);
}


/*start form*/

.form .content-container .form-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
    border-radius: 10px;
}
.form .content-container .form-title {
    color: #00213B;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
    font-size: 30px;
    white-space: nowrap;
}
.form .content-container .form-content .input-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
    gap: 1rem; /* Optional: Add some space between columns */
}
@media (max-width: 768px) {
    .form .content-container .form-content .input-layout {
        grid-template-columns: 1fr; /* Single column for mobile screens */
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .form .content-container .form-content .input-layout {
        grid-template-columns: repeat(2, 1fr); /* Two columns for tablet screens */
    }
}
.form .content-container .form-content .input-container {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    position: relative;
}
.form .content-container .form-content .input-width {
    width: 50%;
}
.form .content-container .form-content .star {
    color: #00213B;
    font-size: 1.5rem;
    font-weight: 500;
    display: block;
    position: absolute;
    top: 1px;
    left: 100%;
}
/*form .label-container {*/
/*    display: flex;*/
/*    gap: 2px;*/

/*}*/
.form .content-container .form-content label {
    color: #00213B;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
    font-size: 1.1rem;
    width: fit-content;
    position: relative;
    display: block;
    padding-top: 5px;
}
.form .content-container .form-content input {
    height: 45px;
    border: 1px solid hsl(0, 0%, 80%);
    /*border: 1px solid #00213B;*/
    border-radius: 6px;
    padding:.5rem;
}
input::placeholder {
    color: #BEBEBE;
    font-size: 14px;
}
.css-19bb58m {
    margin-bottom: -.2rem !important;
}
.css-19bb58m {
    margin-bottom: -1rem;
}

.form .content-container .form-content .PhoneInput {
    height: 45px;
    border: 1px solid hsl(0, 0%, 80%);
    /*border: 1px solid #00213B;*/
    border-radius: 6px;
    padding:.5rem .2rem .5rem .5rem;
}
.form .content-container .form-content .PhoneInputInput {
    border-left: none;
    border-radius: 0;
    border-right: none;
    outline: none;
}
.form .content-container .form-content .PhoneInputInput:focus{
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}


/*End form*/

/*start validation commande*/
.form .content-container .commande .commande-title {
    color: #00213B;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
    font-size: 1.7rem;
    margin-bottom: 2rem;
}
.form .content-container .commande .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form .content-container .commande .space {
    margin-bottom: 1rem;
}
.form .content-container .commande .flex h3 {
    color: #00213B;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-size: 1.3rem;
}
.form .content-container .commande .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.17);
    margin: 1rem 0;
}
.form .content-container .commande .text {
    color: black;
    font-size: .8rem;
    font-weight: 600;
}
.form .content-container .commande .text-container p:not(:last-child) {
    margin-bottom: .5rem;
}
.form .content-container .commande .text-title {
    font-size: 1rem;
    font-weight: 700;
}
.form .content-container .commande .total {
    color: #00213B;
    font-weight: bold;
    font-size: 1.1rem;
}
.form .content-container .commande .sous-total {
    color: #00213B;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: "Inter", sans-serif;
}
.form .content-container .commande .flex-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}
.form .content-container .commande .flex-column .btn {
    font-weight: 700;
    color: white;
    background: linear-gradient(to left, #eaa6a6, #efda66);
    padding: .5rem ;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}
.form .content-container .commande .flex-column .btn:hover {
    background: linear-gradient(90deg, #b5aee6 10%, #ecb3d8 90%);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.form .content-container .commande .flex-column .btn .paypal {
    width: 115px;
    height: auto;
}
.form .content-container .commande .flex-column .btn  .paypal-icon {
    width: 29px
}
.form .content-container .commande .flex-column .btn .text {
    color: black;
    font-weight: bold;
    font-size:1.5rem ;
}
.form .content-container .commande .flex-column .debit-btn {
    font-weight: bold;
    color: white;
    background-color: #3D3A3A;
    padding: .9rem .7rem ;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    font-size: 1.3rem;
}
.form .content-container .commande .flex-column .debit-btn:hover {
    background-color: #000000;
    padding: 1rem .7rem ;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.no-wrap {
    white-space: nowrap;
}
@media (max-width: 1200px) {
    .form .content-container .commande .flex-column .debit-btn {
        font-size: 1.1rem;
    }
}
.paypal-button-container .paypal-button.paypal-button-shape-rect{
    border-radius: 10px;
    background: linear-gradient(45deg, black, transparent) !important;
}
.paypal-button.paypal-button-color-gold, .paypal-button-row.paypal-button-color-gold .menu-button {
    background: linear-gradient(45deg, black, transparent) !important;
}

/*End validation commande */