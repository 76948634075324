.cgv-wrapper {
    padding: 64px 2rem;
    display: flex;
    flex-direction: column;
    align-items:center;
    overflow: hidden;
    /* border: aqua solid; */
  }
  
  .cgv-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    width: 90%;
    max-width: 1400px;
    /* border: red solid; */
  }
  
  .cgv-header {
    width: fit-content;
    text-decoration: underline 3px;
    color : #236aae;
  }
  
  .cgv-section {
    margin: 1rem 0;
    position: relative;
    /* isolation: isolate; */
  }

  .cgv-section-title{
    color: #236aae;
  }
  .cgv-section-subtitle{
    color: #388ddc;
  }
  
  .subtitle-color {
    color: #388ddc;
  }

  .cgv-container a {
    color: #6ccddc;
    text-decoration: underline;
    font-weight:800;
  }
  
  .cgv-section-link {
    font-weight: bold;
    color: initial;
    text-decoration: none;
  }
  
  .underline {
    text-decoration: underline;
  }

  .font-small {
    font-size: 0.9rem;
  }

  a {
    color: #25aabe;
  }

  .ms-2 {
    margin-left: 8px;
  }

  .cgv-footer {
    width: 100%;
    display:flex;
    justify-content: end;
    align-items:center;
  }
  
  .cgv-footer p {
    line-height: 1.2rem;
    font-size: 0.9rem;
  }