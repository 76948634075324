.easy-money {
  transform: translateX(50%);
  border: red solid;
}

.hero-section-wrapper {
  position: relative;
  width: 100vw;
  height: 90vh;
  background-position: center;
  gap: 8px;
  color: white;
}

.testo {
  border: black solid;
}

.hero-section-wrapper > * {
  margin: 0;
}

.hero-section-container {
  position: absolute;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* border: orange solid; */
}
.hero-section-body {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 2vh;
  padding-left: 256px;
}

@keyframes hero-apparition {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    transform: translateY(0);
  }
}

.hero-section-title {
  line-height: 90px;
  text-align: center;
  font-size: 90px;
}
.hero-section-slogan {
  font-size: 30px;
}

.hero-section-container.visible * {
  animation: hero-apparition 0.5s ease-in-out;
}

.hero-section-button {
  margin-top: 16px;
  width: fit-content;
  font-weight: bold;
  font-size: 20px;
  color: white;
  /* background-color: #faa661; */
  background: linear-gradient(90deg, #b5aee6 10%, #ecb3d8 90%);
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.hero-section-button:hover {
  /* background-color: #236aae; */
  background: linear-gradient(to left, #eaa6a6, #efda66);
}

.hero-section-button-weight {
  font-weight: 600;
}

.hero-section-button-logo {
  border-radius: 50px;
  font-size: small;
}

.hero-section-wrapper {
  position: relative;
  overflow: hidden;
}


@media (max-width: 768px) {
  .hero-section-container {
    justify-content: center;
    align-items: center;
  }
  .hero-section-body {
    align-items: center;
    padding: 0;
  }
  .hero-section-title {
    line-height: 70px;
    font-size: 60px;
  }
  .hero-section-slogan {
    font-size: 22px;
  }
}
/*@media (max-width: 600px) {*/
/*  .parallax {*/
/*    background-position: center center !important;*/
/*    background-size: cover !important;*/
/*  }*/
/*}*/


@media (max-width:900px){
  .hero-section-wrapper{
    max-height:1000px;
  }

  .react-parallax-bgimage{
  }

  .hero-section-container{
    justify-content: start;
  }

  .hero-section-body {
    padding-left: 12vw;
  }
}


@media (max-width:550px){

  .hero-section-wrapper{
    /* max-height:480px; */
  }

  .react-parallax-bgimage{
  }

  .hero-section-body{
    padding-left: 0;
  }

  .hero-section-button{
    margin: 0;
  font-size: 16px;
  }

  .hero-section-title{
    font-size: 58px;
    line-height:100%;
  }

  .hero-section-container.visible {
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }

  .hero-section-body{
    padding-bottom: 64px;
  }
}

@media (max-width:400px){

  .hero-section-wrapper{
    height:90vh;
  }

  .react-parallax-bgimage{
    /* max-height:100%; */
  }

  .hero-section-title {
    font-size: 56px;
    line-height:100%;
  }

  .hero-section-slogan {
    font-size: 20px;
  }

  .hero-section-button {
    padding: 5px 4px;
    font-size: 13px;
  }

  .hero-section-container.visible {
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }

  .hero-section-body{
    padding-bottom: 32px;
  }
}


