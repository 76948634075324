.pricing-card-container {
    width: 80%;
    background-size: 110%;
    background-position: center;
    text-decoration: none;
    justify-self: center;
    box-shadow: inset 100vw 0 0 0 #2b455a5c;
  
    color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    gap: 1.5vh;
    padding: 32px;
    /* width: 100%; */
    /* width: 92%; */
    aspect-ratio: 16/9;
    transition: all 0.3s ease-in-out;
    /* border: orange solid; */
  }
  
  .pricing-card-container:hover {
    background-size: 112%;
    transform: translateY(-10px);
    box-shadow: inset 100vw 0 0 0 #608db35c;
    /* background: linear-gradient(to left, #eaa6a6, #efda66); */
    box-shadow: inset 100vw 0 0 0 #608db35c;
  }

  .pricing-card-container-header {
    display: flex;
    /* border: green solid; */
  }
  
  .pricing-card-container-icon {
    aspect-ratio: 1/1;
  }
  
  .pricing-card-container-title {
    /* font-family: "Expletus Sans"; */
  }
  
  .pricing-card-container-description {
    position: relative;
    text-decoration: none;
  }
  
  .pricing-card-container-description:before {
    content: "";
    border-bottom: 1px solid white;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    transition: 0.3s;
  }
  
  .pricing-card-container:hover .pricing-card-container-description:before {
    width: 100%;
    padding: 0;
  }

  .pricing-card-container:hover .pricing-card-container-button {
    background: linear-gradient(to right, #b5aee6, #ecb3d8);
    border-radius: 12px;
    /* padding: 3px 5px; */
  }
  
  .pricing-card-container-button {
    color: white;
    width: fit-content;
    background: transparent;
    padding: 7px
  }
  
  .pricing-card-container-button:hover {
    cursor: pointer;
    background: linear-gradient(to right, #b5aee6, #ecb3d8);
    /* color: #9dd3ffed; */
  }
  
  @media (max-width: 768px) {
    .pricing-section-details {
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: 1fr;
    }
  }
  