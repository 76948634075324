.btn-up-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
  opacity: 0;
  transition: ease 0.9s;
}

.btn-up-container.visible {
  opacity: 1;
}

.btn-up {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s, transform 0.3s;
}

.btn-up:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

.btn-up span {
  margin-left: 8px;
  font-size: 16px;
  color: #333;
}
