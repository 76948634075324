.phs-accessibility-notice-wrapper {
    margin: 64px 16px;
    display: flex;
    flex-direction: column;
    align-items:center;
    overflow:hidden;
    /* border: aqua solid; */
  }
  
  .phs-accessibility-notice-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    width: 90%;
    max-width: 1400px;
    /* border: red solid; */
  }
  
  .phs-accessibility-notice-header {
    width: fit-content;
  }

  .phs-accessibility-notice-header h1 {
    color : #236aae;
    text-decoration: underline 3px;
  }
  
  .phs-accessibility-notice-section {
    margin: 1rem 0;
    position: relative;
  }

  .phs-accessibility-notice-section-title{
    color: #388ddc;
  }
  
  .phs-accessibility-notice-section-link {
    font-weight: bold;
    color: initial;
    text-decoration: none;
  }
  
  .underline {
    text-decoration: underline;
  }

  .font-small {
    font-size: 0.9rem;
  }

  a {
    color: #25aabe;
  }

  .ms-2 {
    margin-left: 8px;
  }
  .mt-0 {
    margin-top: 0;
  }

  .phs-accessibility-notice-footer {
    width: 100%;
    display:flex;
    justify-content: end;
    align-items:center;
    margin: 1rem 0  0 0;
  }
  
  .phs-accessibility-notice-footer p {
    line-height: 1.2rem;
    font-size: 0.9rem;
  }