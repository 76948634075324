.home-page-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: #f0f2f8;
  /* border: red solid; */
}

.home-sections-wrapper {
  isolation: isolate;
  position: relative;
  background-color: transparent;
  min-width: min-content;
  width: 90%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6vh 0;
  gap: 2vh;
  /* border: aqua solid; */
}
