.strie-container {
  width: 110px;
  height: 22px;
  border-radius: 40px;
  position: absolute;
  background-size: 100% 100%;
  animation: strie-apparition 5s ease-in-out, gradient 5s ease-in-out infinite;
}

.strie-container.red {
  background: linear-gradient(to right, #b5aee6, #ecb3d8);
}

.strie-container.blue {
  background: linear-gradient(to left, #eaa6a6, #efda66);
}

@keyframes strie-apparition {
  from {
    scale: 0.5;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .strie-container {
    width: 55px;
    height: 11px;
  }
}

@media (max-width: 920px) and (min-width: 768px) {
  .strie-container {
    width: 100px;
    height: 15px;
  }
}


@media only screen and (min-width: 600px) and (max-width: 999px) {
  .striebig{
      left: -5% !important;
  }

}

