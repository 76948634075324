/*****************************************/
.formation-section-wrapper {
  color: white;
  isolation: isolate;
  position: relative;
  background-color: transparent;
  min-width: min-content;
  width: 100%;
  /* max-width: 1400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 6vh 0; */
  gap: 2vh;
  height: fit-content;
  /* min-height: 100vh; */
  background-color: #493d9b;
  /* border: aqua solid; */
  padding: 40px 0 50px 0;
}

.formation-section-triangle {
  top: -2px;
  position: absolute;
  width: 70px;
  height: 60px;
  background-color: white;
  clip-path: polygon(100% 0, 0 0, 49% 55%);
  rotate: 180deg;
  transform: translateY(95%);
  background-color: #493d9b;
}

.formation-section-container {
  width: 50%;
  min-width: min-content;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  gap: 3vh;
  /* border: greenyellow solid; */
  margin: 32px 16px;
}

.formation-section-title {
  width: fit-content;
  text-align: center;
  font-family: Raleway;
  /* border: green solid; */
}

.formation-section-text {
  width: 50%;
  text-align: center;
}
.formation-section-details {
  /* align-items: center; */
  /* justify-content: center; */
  margin-top: 3vh;
  width: 90%;
  display: grid;
  row-gap: 22px;
  column-gap: 22px;
  /* column-gap: 2vh; */
  /* grid-auto-columns: auto; */
  grid-template-columns: 1fr 1fr;
  /* border: orange solid; */
}

@media (max-width: 900px) {
  .formation-section-details {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;
    width: 90%;
    gap: 3rem;
  }

  .formation-section-details .pricing-card-container{
    width: 100%;
  }

  .formation-section-text {
    width: 90%;
    color: #e0e0e0;
  }
  .formation-section-container {
    width: 90%;
  }
  .formation-section-title{
    font-size: 2.5rem;
  }
}

@media screen and (min-width:545px) and (max-width: 768px){
  .formation-section-container {
    width: 500px;
  }
}


@media screen and (min-width: 900px){
  .formation-section-wrapper{
    /* padding: 120px 0 120px 0; */
    padding: 50px 0 80px 0;
  }

  .formation-section-container {
    width: 80%;
    max-width: 1400px;
  }
}


@media screen and (min-width: 1440px){
  .formation-section-wrapper {
    padding: 30px 0 70px 0;
  }
}