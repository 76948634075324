.banner-section-wrapper {
  background-color: transparent;
  width: 100%;
}

.banner-section-container {
  width: 100%;
  height: 50vh;
  /* border: greenyellow solid; */
}

.banner-section-formation-section-triangle {
  top: -2px;
  position: absolute;
  width: 70px;
  height: 60px;
  background-color: white;
  clip-path: polygon(100% 0, 0 0, 49% 55%);
  /* rotate: 180deg; */
  /* transform: translateY(95%); */
  background-color: #493d9b;
}
