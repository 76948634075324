body {
  margin: 0;
}


*,*::before,*::after{
  box-sizing: border-box;
}
button,
div,
h1,
h2,
h3,
h4,
p {
  margin: 0;
  border: 0;
}

