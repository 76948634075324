.presentation-section-container {
  width: 70%;
  min-width: min-content;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  gap: 64px;
  /* border: greenyellow solid; */
}

@keyframes presentation-apparition {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
  }
}

.presentation-section-container.visible {
  animation: presentation-apparition 0.5s ease-in-out;
}

.presentation-section-description {
  min-width: 300px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6vh;
}

.presentation-section-item {
  display: flex;
  gap: 16px;
}

.presentation-section-item-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.presentation-section-item-body p {
  font-size: 14px;
}

.presentation-section-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.presentation-section-button {
  width: fit-content;
  text-decoration: none;
  position: relative;
  color: #236aae;
}
.presentation-section-button:hover {
  color: #25aabe;
}

.presentation-section-button:before {
  content: "";
  border-bottom: 1px solid #25aabe;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  transition: 0.3s;
}

.presentation-section-button:hover:before {
  width: 100%;
  padding: 0;
}

.presentation-section-illustration {
  position: relative;
  min-width: 300px;
  max-height: 50vw;
  flex: 1;
  aspect-ratio: 1/1;
  /* border: red solid; */
}

.presentation-section-image {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 7px;
}

.presentation-section-container-circle {
  position: absolute;
  bottom: 64px;
  left: -20%;
  width: 50%;
  /* height: 50%; */
  aspect-ratio: 1/1;
  border-radius: 100%;
  border: #e7d5f8 solid 8px;
  overflow: hidden;
}

.presentation-section-image-circle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  /* border: purple solid; */
}

@media (max-width:420px){
  .presentation-section-container-circle{
    left: -15%;
    bottom: 90px;
  }

}

@media (max-width:380px){
  .presentation-section-container-circle{
    left: -11%;
    bottom: 90px;
  }
}

@media (max-width:370px){
  .presentation-section-container-circle{
    left: -5%;
  }
}




/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles for extra small devices */
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles for small devices */
}

/* Medium devices (tablets, 768px and up) */
@media(max-width: 991.98px) {
  /* Your styles for medium devices */
  .presentation-section-container{
    width: 90%;
  }
  .presentation-section-illustration{
    width: 100%;
  }
  .presentation-section-container-circle.mobile{
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles for large devices */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles for extra large devices */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles for extra extra large devices */
}