/***********************************/
/***************DESIGN SYSTEM**********/

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&family=Raleway:ital,wght@0,700;1,700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
/******************************/
/*********FONT STYLE**********/
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
button,
label,
input,
textarea,
span {
  margin: 0; /* A COMPREND PK !!!!*/
  font-weight: 400;
}

h1 {
  font-family: "Raleway";
  font-weight: 700;
}
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand";
  font-weight: 600;
}
body {
  color: #00213b;
  font-family: "Work Sans";
}
/* div {
  color: #2c2e35;
} */

.bold {
  font-weight: 700;
}
.semi-bold {
  font-weight: 600;
}
.regular {
  font-weight: 400;
}

h1 {
  font-size: 47.8px;
  letter-spacing: -2px;
}

h2 {
  font-size: 39.8px;
  letter-spacing: -1px;
}

h3 {
  font-size: 33.2px;
  letter-spacing: -1px;
}

h4 {
  font-size: 27.6px;
}

h5 {
  font-size: 23px;
}

h6 {
  font-size: 19.2px;
}

p,
button {
  font-family: "Work Sans";
  font-size: 13px;
}

/* Media queries pour les petits écrans */
@media (max-width: 768px) {
  h1 {
    font-size: 32.44px;
  }

  h2 {
    font-size: 28.83px;
  }

  h3 {
    font-size: 25.63px;
  }

  h4 {
    font-size: 22.78px;
  }

  h5 {
    font-size: 20.25px;
  }

  h6 {
    font-size: 18px;
  }

  p {
    font-size: 0.875em;
    line-height: 1.4;
  }

  /* Ajoutez d'autres éléments et leurs styles ici */
}

/***********************************/

html {
  scroll-behavior: smooth;
}
