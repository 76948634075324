.ado-formation-container {
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vh;
  color: #00213b;
}

.ado-formation-header {
  width: 100%;
  overflow: visible;
}

.numerique-14-18 .ado-formation-header-left {
  position: relative;
  text-align: start;
}
.ado-formation-info {
  position: relative;
}
.ado-formation-info .strie-info {
  display: none;
}
.ado-formation-price .strie-info{
  display: none;
}
.numerique-14-18 .ado-formation-price .strie-info{
  display: block;
}
.numerique-14-18 .ado-formation-info .strie-info {
  display: block;
}
.numerique-14-18 .ado-formation-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background-color: #5393bd;
  height: 42vh;
  p,
  span {
    color: white;
  }
}
.numerique-14-18 .vacances-scolaires {
  position: relative;
}
.numerique-14-18 .ado-formation-header-title {
  font-size: 3.7rem;
}
.numerique-14-18 .logo-img {
  height: 364px;
}
.numerique-14-18 .ado-formation-span-formation {
  text-align: justify;
  margin-top: 57px;
  font-size: 44px;
  color: #5393bd;
}
.numerique-14-18 .ado-formation-header {
  position: relative;
  height: 44vh;
}
.numerique-14-18 .ado-formation-header-image {
  position: absolute;
  height: 78vh;
}
.numerique-14-18 .ado-formation-competences {
  position: relative;
}
.numerique-14-18 .ado-formation-competences-title {
  text-align: justify;
  color: #b3aee2;
}

/*.numerique-14-18 .ado-formation-price-right h1 {
  font-size: 7rem;
}
.numerique-14-18 .ado-formation-price-list {
  font-size: 25px;
  }
  .numerique-14-18 .ado-formation-competence-title {
    background: linear-gradient(90deg, rgb(215 178 212) 10%, rgb(88 149 189) 90%);
    background: linear-gradient(to left, #d9b3d4, #5694bd);
    text-align: justify;
    }
    */
    .numerique-14-18 .ado-formation-price {
      background-color: #5393bd;
      }
      
      .numerique-14-18 .ado-formation-price-right {
      width: 0;
       
      }
.numerique-14-18 .ado-formation-footer-image {
  height: 598px;
  /* width: 627px; */
  position: absolute;
  top: 0;
  left: 20px;
}
/*
.numerique-14-18 .ado-formation-price {
  min-height: 541px;
  justify-content: flex-end;
  align-items: flex-end;
}*/
.numerique-14-18 .strie-container.red {
  background: linear-gradient(to right, #d9c68d, #e8d179);
  transform: scale(3) rotate(-39deg) !important;
  height: 16px;
  animation: none !important;
}
.numerique-14-18 .strie-container.red .strie-footer {
  transform: scale(3) rotate(-41deg) !important;
  height: 18px;
}
.numerique-14-18 .ado-formation-contact-button{
  background-color: #5393bd;
}
.numerique-14-18 .ado-formation-contact-button:hover{
  background-color: #73bced;
}
.numerique-14-18 .ado-formation-download-button{
  background-color: #5393bd;
}
.numerique-14-18 .ado-formation-download-button:hover{
  background-color: #73bced;
}
@media (min-width: 1024px) {
  .numerique-14-18 .strie-container.red {
    height: "33" px;
    width: 71px;
  }
  .numerique-14-18 .logo-img {
    height: 100%;
  }
  .numerique-14-18 .ado-formation-span-formation {
    font-size: 39px;
    margin-top: 0;
  }
  .numerique-14-18 .ado-formation-header-title {
    font-size: 2.7rem;
  }
  /*.numerique-14-18 .ado-formation-price-right h1 {
    font-size: 6rem;
  }*/
  .numerique-14-18 .ado-formation-info {
    height: 55vh;
  }
}
@media (max-width: 768px) {
  /*.numerique-14-18 .ado-formation-footer-image {
    top: 0;
    left: -154px;
    height: 782px;
  }*/

  .numerique-14-18 .logo-img {
    height: 228px;
  }
  .numerique-14-18 .ado-formation-span-formation {
    font-size: 29px;
    margin-top: 0;
  }
  .numerique-14-18 .ado-formation-header-title {
    font-size: 2rem;
  }
  /*.numerique-14-18 .ado-formation-price-right h1 {
    font-size: 4rem;
  }
  .numerique-14-18 .ado-formation-price-list {
    font-size: 23px;
  }
  .numerique-14-18 .ado-formation-price {
    min-height: 466px;
  }*/
  .numerique-14-18 .ado-formation-contact-button {
    font-size: 20px;
    /*padding: 8px 8px;*/
  }
  .numerique-14-18 .ado-formation-header-left {
    width: 46%;
    margin: 0;
    margin-left: 36px;
  }
  .numerique-14-18 .ado-formation-header {
    position: relative;
    height: 56vh;
  }
  .numerique-14-18 .ado-formation-header-image {
    position: absolute;
    height: 97vh;
  }
  .numerique-14-18 .strie-container.red {
    background: linear-gradient(to right, #d9c68d, #e8d179);
    transform: scale(3) rotate(-40deg) !important;
    width: 74px;
    height: 16px;
    animation: none !important;
    filter: brightness(50%);
}
  }
  /* .numerique-14-18 .footer{
    transform: scale(3) rotate(-39deg) !important;
  width: 73px !important;
  height: 11px !important;
  }*/


@media (max-width: 425px) {
  .numerique-14-18 .strie-container.red {
    top: 72% !important;
    left: -22px !important;
    right: 0px;
    z-index: 1;
    width: 42px !important;
  }
  .numerique-14-18 .ado-formation-header-image {
    height: 43vh;
  }
  .numerique-14-18 .logo-img {
    height: 114px;
  }
  .numerique-14-18 .ado-formation-header-left {
    width: 46%;
    margin: 0;
    margin-left: 0;
  }
  .numerique-14-18 .ado-formation-header-title {
    font-size: 1.5rem;
  }
  .numerique-14-18 .ado-formation-span-formation {
    font-size: 24px;
  }
  .numerique-14-18 .ado-formation-header {
    height: 31vh;
  }
 
  .numerique-14-18 .ado-formation-price {
    min-height: 218px;
  }
  /*.numerique-14-18 .ado-formation-price-right h1 {
    font-size: 2rem;
  }
  .numerique-14-18 .ado-formation-price-list {
    font-size: 10px;
  }*/
  .numerique-14-18 .ado-formation-contact-button {
    font-size: 16px;
  }
  /*.numerique-14-18 .ado-formation-footer-image {
    left: -52px;
    height: 291px;
  }
  .numerique-14-18 .ado-formation-footer-image {
    left: -80px;
    height: 409px;
  }
  .numerique-14-18 .ado-formation-price-right h1 {
    font-size: 1.5rem;
  }
  .numerique-14-18 .ado-formation-price {
    min-height: 255px;
  }*/
}
@media (max-width: 320px) {
  .numerique-14-18 .ado-formation-header-image {
    height: 35vh;
  }
  .numerique-14-18 .logo-img {
    height: 70px;
  }
  .numerique-14-18 .ado-formation-span-formation {
    font-size: 20px;
  }
  .numerique-14-18 .ado-formation-header-title {
    font-size: 1rem;
  }
  .numerique-14-18 .ado-formation-header {
    height: 22vh;
  }
  .numerique-14-18 .ado-formation-footer-image {
    left: -120px;
    height: 483px;
  }
  /*.numerique-14-18 .ado-formation-price-right {
    width: 40%;
    min-width: min-content;
  }
  .numerique-14-18 .ado-formation-price {
    min-height: 218px;
  }
  .numerique-14-18 .ado-formation-price-right h1 {
    font-size: 2rem;
  }
  .numerique-14-18 .ado-formation-price-list {
    font-size: 10px;
  }*/
  .numerique-14-18 .ado-formation-contact-button {
    font-size: 16px;
  }
  .numerique-14-18 .strie-container.red {
    display: none;
  }
}

@media (min-width: 1024px) {
  .ado-formation-header {
    height: fit-content;
    min-height: 320px;
  }
}

.ado-formation-header-title {
  letter-spacing: -0.05em;
}

.ado-formation-header-left {
  z-index: 2;
  text-wrap: balance;
  width: 55%;
  /*  */
  width: 40%;
  margin-right: auto;
  margin-left: 5%;
}

.ado-formation-span-formation {
  color: #978ce0;
  text-align: center;
  font-size: 42px;
}

.ado-formation-header-image-container {
  top: 0;
  right: 0;
}

.ado-formation-header-image {
  position: absolute;
  top: 0;
  right: 0;
  height: 300px;
}

@media (min-width: 1024px) {
  .ado-formation-header-image {
    height: 400px;
  }
}

.ado-formation-header-layer {
  z-index: -1;
  scale: 1.3;
  position: absolute;
  top: -4%;
  right: -12%;
  width: 400px;
  height: 180px;
  border-radius: 100px;
  animation: strie-apparition 5s ease-in-out, gradient 5s ease-in-out infinite;
  transform: rotate(-50deg);
}

.ado-formation-body {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  align-items: center;
  width: 100%;
}

.ado-formation-info-text {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
  width: 70%;
  color: white;
  z-index: 2;
}

.ado-formation-competences {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: min(800px, 90%);
}
.ado-formation-competences-title {
  color: #978ce0;
  font-size: 40px;
  font-weight: 600;
}
.ado-formation-competence-container {
  display: flex;
  flex-direction: column;
}

.ado-formation-competence-title {
  font-size: 24px;
  font-weight: 600;
  /* background: linear-gradient(
      90deg,
      rgba(243, 93, 171, 1) 10%,
      rgba(253, 204, 17, 1) 90%
    ); */
  background: linear-gradient(to left, #eaa6a6, #efda66);
  /* padding: 4px 16px; */
  padding: 10px 16px;
  border-radius: 8px;
}

.ado-formation-competence-list {
  padding: 0;
}

.ado-formation-competence-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: start;
}
.ado-formation-competence-list-item::before {
  color: #fdce2a;
  content: "•";
  font-size: 36px;
}

.ado-formation-footer-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 300px;
}

.ado-formation-price {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  background-color: #978ce0;
  color: white;
  padding: 32px 0px;
  /* overflow hidden for stries */
  overflow: hidden;
  min-height: 300px;
}

.ado-formation-price-right {
  width: 40%;
  z-index: 2;
  min-width: fit-content;
  padding-right: 4px;
}
.ado-formation-price-list {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: start;
}
.ado-formation-price-list::before {
  color: #fdce2a;
  content: "•";
  font-size: 36px;
}

.ado-formation-price-right h1 {
  text-align: start;
}
.ado-formation-price-list li {
  text-align: start;
  overflow: hidden;
}

.ado-formation-agency-text {
  font-size: 18px;
  /* font-family: "Expletus Sans"; */
  color: #8b81cc;
}

.ado-formation-footer {
  display: flex;
  justify-content: center;
  /* gap: 64px; */
  gap: 24px;
  /* width: 55%; */
}

.ado-formation-contact-button {
  color: white;
  /* background: linear-gradient(to right, #b5aee6, #ecb3d8); */
  background-color: #8b81cc;
  padding: 20px 32px;
  border-radius: 20px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.ado-formation-contact-button:hover {
  /* background: linear-gradient(to left, #eaa6a6, #efda66); */
  background-color: #978ce0;
  cursor: pointer;
}

.ado-formation-download-button {
  color: white;
  /* background: linear-gradient(to right, #b5aee6, #ecb3d8); */
  background-color: #8b81cc;
  padding: 20px 32px;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}
.ado-formation-download-button:hover {
  /* background: linear-gradient(to left, #eaa6a6, #efda66); */
  /* background: linear-gradient(to right, #b5aee6, #ecb3d8); */
  background-color: #978ce0;
  cursor: pointer;
}

.ado-formation-booking-button {
  color: white;
  /* background: linear-gradient(to right, #b5aee6, #ecb3d8); */
  background-color: #8b81cc;
  padding: 20px 32px;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
.ado-formation-booking-button:hover {
  /* background: linear-gradient(to left, #eaa6a6, #efda66); */
  /* background: linear-gradient(to right, #b5aee6, #ecb3d8); */
  background-color: #978ce0;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .ado-formation-download-button {
    text-wrap: nowrap;
  }

  .ado-formation-booking-button {
    text-wrap: nowrap;
  }

  .ado-formation-body {
    gap: 5vh;
  }
}

@media (max-width: 965px) {
  .ado-formation-header-image {
    height: 260px;
  }
  .ado-formation-footer {
    /* width: 70%; */
    flex-wrap: wrap;
    justify-content: center;
  }

  /*.ado-formation-contact-button {
    width: 240px;
  }*/

  .ado-formation-download-button {
    text-wrap: nowrap;
  }

  .ado-formation-booking-button {
    text-wrap: nowrap;
  }

  .ado-formation-info-text {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .ado-formation-container {
    gap: 3vh;
  }

  .ado-formation-body {
    gap: 2vh;
  }

  .ado-formation-header-image {
    height: 140px;
  }
  .ado-formation-header h1 {
    font-size: 22px;
  }
  .ado-formation-day-number {
    font-size: 18px;
  }
  .ado-formation-day-title {
    font-size: 18px;
    padding-left: 30px;
  }
  .ado-formation-day-body {
    padding: 0;
  }
  .ado-formation-day-list-actvities {
    width: 95%;
  }
  .day-activite-name {
    font-size: 14px;
  }

  .ado-formation-footer-image {
    height: 300px;
    /* left: -200px; */
    filter: brightness(50%);
  }
  .ado-formation-footer {
    width: 80%;
    flex-direction: column;
    gap: 2vh;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 24px;
  }

  .ado-formation-contact-button,
  .ado-formation-download-button,
  .ado-formation-booking-button {
    padding: 20px 32px;
    width: 260px;
    text-align: center;
  }

  .ado-formation-competences-title {
    font-size: 22px;
  }

  .ado-formation-competence-title {
    font-size: 20px;
  }

  .ado-formation-price-right {
    width: 80%;
    z-index: 2;
    /* border: orange solid; */
  }

  .ado-formation-header-left {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 550px) {
  .ado-formation-header-left {
    width: 45%;
  }

  .ado-formation-header-image {
    height: 120px;
  }

  .ado-formation-competence-list li {
    margin-bottom: 8px;
    font-size: 14px;
    text-align: start;
  }

  .ado-formation-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

  .ado-formation-contact-button,
  .ado-formation-download-button,
  .ado-formation-booking-button {
    width: 70vw;
    font-size: 13px;
  }

  .ado-formation-competence-title {
    font-size: 16px;
  }
}
