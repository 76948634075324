.kids-formation-container {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vh;
    color: #00213b;
  }
  
  .kids-formation-header {
    position: relative;
    width: 100%;
    overflow: visible;
    height:400px;
  }
  .kids-formation-header-left {
    z-index: 2;
    text-wrap: balance;
    width: 40%;
    margin-right:auto;
    margin-left: 5%;
    margin-top: 64px;
}

  .kids-formation-longue-header-title {
    letter-spacing: -0.05em;
  }
  
  .kids-formation-longue-span-formation {
    color: #978ce0;
    text-align: center;
    font-size: 42px;
    letter-spacing: -0.05em;
  }
  
  .kids-formation-header-image-container {
    top: 0;
    right: 0;
  }
  
  .kids-formation-header-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 400px;
  }
  
  .kids-formation-header-layer {
    z-index: -1;
    scale: 1.3;
    position: absolute;
    top: -4%;
    right: -12%;
    width: 400px;
    height: 180px;
    border-radius: 100px;
    animation: strie-apparition 5s ease-in-out, gradient 5s ease-in-out infinite;
    transform: rotate(-50deg);
  }
  
  .kids-formation-body {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    align-items: center;
    width: 100%;
  }
  
  .kids-formation-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #978ce0;
  }
  
  .kids-formation-info-text {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    width: 70%;
    color: white;
    z-index:2;
  }
  
  .kids-formation-competences {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: min(800px, 90%);
  }
  .kids-formation-competences-title {
    color: #978ce0;
    font-size: 40px;
    font-weight: 600;
  }
  .kids-formation-longue-competence-container {
    display: flex;
    flex-direction: column;
  }
  
  .kids-formation-longue-competence-title {
    font-size: 24px !important;
    font-weight: 600 !important;
    /* background: linear-gradient(
      90deg,
      rgba(243, 93, 171, 1) 10%,
      rgba(253, 204, 17, 1) 90%
    ); */
    background: linear-gradient(to left, #eaa6a6, #efda66);
    /* padding: 4px 16px; */
    padding: 10px 16px;
    border-radius: 8px;
  }
  
  .kids-formation-longue-competence-list {
    padding: 0;
  }
  .kids-formation-longue-competence-list-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .kids-formation-longue-competence-list-item::before {
    color: #fdce2a;
    content: "•";
    font-size: 36px;
  }
  
  .kids-formation-footer-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
  }
  
  .kids-formation-price {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
    background-color: #978ce0;
    color: white;
    padding: 32px 0px;
    /* overflow hidden for stries */
    overflow: hidden;   
    min-height:300px;
  }
  
  .kids-formation-price-right {
    width: 40%;
    z-index: 2;
    min-width: fit-content;
    padding-right: 4px;
  }
  .kids-formation-price-list {
    padding: 0;
    list-style: none;
  }

  .kids-formation-price-right h1{
    text-align: start;
  }
  .kids-formation-price-list li{
    text-align: start;
    overflow:hidden;
  }

  .kids-formation-agency-text {
    font-size: 18px;
    /* font-family: "Expletus Sans"; */
    color: #8b81cc;
  }
  
  .kids-formation-footer {
    display: flex;
    justify-content: center;
    gap: 64px;
    width: 55%;
    margin-bottom: 24px;
  }
  
  .kids-formation-contact-button {
    color: white;
    /* background: linear-gradient(to right, #b5aee6, #ecb3d8); */
    background-color: #8b81cc;
    padding: 20px 32px;
    border-radius: 20px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }
  .kids-formation-contact-button:hover {
    /* background: linear-gradient(to left, #eaa6a6, #efda66); */
    background-color: #978ce0;
    cursor: pointer;
  }
  
  .kids-formation-download-button {
    color: white;
    /* background: linear-gradient(to right, #b5aee6, #ecb3d8); */
    background-color: #8b81cc;
    padding: 20px 32px;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
  }
  .kids-formation-download-button:hover {
    /* background: linear-gradient(to left, #eaa6a6, #efda66); */
    /* background: linear-gradient(to right, #b5aee6, #ecb3d8); */
    background-color: #978ce0;
    cursor: pointer;
  }

  @media (max-width: 1024px){
    .kids-formation-download-button {
      text-wrap: nowrap;
    }

    .kids-formation-body {
      gap: 2vh;
    }
  }


    @media (max-width: 965px) {
    .kids-formation-header-image {
      height: 260px;
    }
    .kids-formation-footer {
      width: 70%;
    }

    .kids-formation-header{
      height:fit-content;
    }

    .kids-formation-download-button {
      text-wrap: nowrap;
    }
  
    .kids-formation-info-text {
      width: 90%;
    }

    .kids-formation-body {
      margin-top: 0;
    }
  }

  @media (max-width: 768px) {
    .kids-formation-container {
      gap: 3vh;
      margin-right:12px;
    }
    .kids-formation-header-image {
      height: 140px;
    }
    .kids-formation-header h1 {
      font-size: 22px;
    }
    .kids-formation-longue-day-number {
      font-size: 18px;
    }
    .kids-formation-longue-day-title {
      font-size: 18px;
      padding-left: 30px;
    }
    .kids-formation-longue-day-body {
      padding: 0;
    }
    .kids-formation-longue-day-list-actvities {
      width: 95%;
    }
    .day-activite-name {
      font-size: 14px;
    }
  
    .kids-formation-footer-image {
      height: 300px;
      /* left: -200px; */
      filter: brightness(50%);
    }
    .kids-formation-footer {
      width: 80%;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 24px;
      gap: 2vh;
    }
    .kids-formation-contact-button,
    .kids-formation-download-button {
      padding: 10px 16px;
    }
  
    .kids-formation-competences-title {
      font-size: 22px;
      letter-spacing: -0.05em;
    }
  
    .kids-formation-longue-competence-title {
      font-size: 20px;

    }
  
    .kids-formation-price-right {
      width: 80%;
      z-index: 2;
      /* border: orange solid; */
    }
  
    .kids-formation-header-left {
      width: 100%;
      margin: 0;
    }
  }
  

  @media (max-width:550px){
    .kids-formation-header-left{
      width: 65%;
    }

    .kids-formation-header-image {
      height: 120px;
    }

    .kids-formation-body{
      gap: 1.5vh;
    }

    .kids-formation-longue-competence-title{
      font-size: 18px !important;
    }


    .kids-formation-footer {
      flex-direction: column;
      align-items: center;
    }

    .kids-formation-contact-button,
    .kids-formation-download-button {
      width: 240px;
    }
  }