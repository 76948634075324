.kids-hero-section-wrapper {
    position: relative;
    width: 100vw;
    height: 80vh;
    background-position: center;
    gap: 8px;
    color: white;
  }
  
  .kids-hero-section-wrapper > * {
    margin: 0;
  }
  
  .kids-hero-section-background {
    width: 200%;
    background-size: cover;
  }
  
  .kids-hero-section-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 70vh;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .kids-hero-section-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 1vh;
    padding-bottom: 80px;
    align-self: flex-end;
    margin-bottom: 2em;
  }
  
  @keyframes kids-hero-apparition {
    from {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .kids-hero-section-title {
    color: #fff;
    line-height: 90px;
    text-align: center;
    font-weight: bold;
    font-size: 70px;
  }
  
  .kids-hero-section-container.visible * {
    animation: kids-hero-apparition 0.5s ease-in-out;
  }
  
  .kids-hero-section-cta {
    font-weight: bold;
    font-size: 20px;
    color: white;
    background: linear-gradient(to right, #b5aee6, #ecb3d8);
    padding: 10px 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .kids-hero-section-cta:hover {
    background: linear-gradient(to left, #eaa6a6, #efda66);
  }
  
  .kids-hero-section-cta-logo {
    border-radius: 50px;
    font-size: small;
  }
  
  .kids-hero-section-wrapper {
    position: relative;
    overflow: hidden;
  }

  
  
  @media (max-width: 768px) {
    .kids-hero-section-container {
      align-items: center;
    }
    .kids-hero-section-body {
      padding-bottom: 0px;
    }
    .kids-hero-section-title {
      line-height: 70px;
      font-size: 50px;
    }
    .kids-hero-section-slogan {
      font-size: 22px;
    }

    .parallax-image-container img{
       background-position: center;
    }
  }
  @media (max-width: 1500px) {
    .kids-hero-section-container{
      background-color: rgba(0, 0, 0, 0.4);

    }
    .kids-hero-section-title{
      color: #fff;
    }
  }
  