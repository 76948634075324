.kids-page-paragraph-container{
    padding:  1em;
    width: 100%;
    
    text-align: center;
    z-index: 10;
    background-color: #fff;
    display: flex;
    
}
.kids-page-paragraph-container p{
    font-size: 1.7rem;
    max-width: 900px;
    font-weight: bold;
    margin: auto;
}

.kids-page-paragraph-upper-container{
    /* isolation: isolate; */
    position: relative;
    background-color: transparent;
    min-width: min-content;
    width: 90%;
    /* max-width: 1400px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh 0;
    gap: 1vh;
    margin: auto;

}


@media (max-width: 768px){
    .kids-page-paragraph-container p{
        font-size: 1.2rem;
    }
}