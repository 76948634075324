.contact-container {
  width: 100%;
  max-width: 600px;
  /* min-width: 340px; */
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vh;
  border-radius: 15px;
  background-color: rgb(252 252 252 / 63%);
  overflow: visible;
  position: relative;
  padding-bottom: 35px;
  /* border: red solid; */
  h1{
    font-size:30px;
  }
}

@media (max-width: 991.98px) {
  .contact-container {
    width:min(90%,400px);
    /*max-width: 100%;*/
  }

  .contact-wrapper {
    padding: 0;
  }
}

@media (max-width:380px){
  .contact-container h2{
    text-align: center;
  }
}