.confidentiality-notice-wrapper {
    margin: 64px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .confidentiality-notice-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    width: 90%;
    max-width: 1400px;
  }
  
  .confidentiality-notice-header {
    width: fit-content;
    text-decoration: underline 3px;
    color : #236aae;
  }
  
  .confidentiality-notice-section {
    margin: 1rem 0;
    position: relative;
  }

  .confidentiality-notice-section-title{
    color: #236aae;
  }
  .confidentiality-notice-section-subtitle{
    color: #388ddc;
  }
  
  .subtitle-color {
    color: #388ddc;
  }

  .cgv-container a {
    color: #6ccddc;
    text-decoration: underline;
    font-weight:800;
  }
  
  .confidentiality-notice-section-link {
    font-weight: bold;
    color: initial;
    text-decoration: none;
  }
  
  .underline {
    text-decoration: underline;
  }

  .font-small {
    font-size: 0.9rem;
  }

  a {
    color: #25aabe;
  }

  .ms-2 {
    margin-left: 8px;
  }

  .confidentiality-notice-footer{
    width: 100%;
    display:flex;
    justify-content: end;
    align-items:center;
  }
  
  .confidentiality-notice-footer p {
    line-height: 1.2rem;
    font-size: 0.9rem;
  }