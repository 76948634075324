/***************************************************/
.categorie-section-wrapper {
  position: relative;
  background-color: white;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15vh 0;
  gap: 6vh;
  /* border: aqua solid; */
}

.categorie-section-container {
  text-align: center;
  width: 70%;
  height: auto;
  max-width: 1000px;
  display: flex;
  /* grid-template-columns: repeat(3,1fr); */
  gap: 32px;
  /* border: red solid; */
}

.categorie-section-header {
  width: 50%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1vh;
}

.categorie-section-title {
  text-wrap: balance;
  /* border: blue solid; */
  width: 100%;
  margin-bottom: 16px;
}

.categorie-section-text{
  text-align: start;
  width: 100%;
}

@media (max-width: 768px) {
  .categorie-section-container {
    width: 90%;
    flex-direction: column;
  }
}

@media(max-width: 991.98px){
  .categorie-section-wrapper {
    padding: 7rem 1rem;
  }
  .categorie-section-header{
    width: 90%;
    padding: 6vh 0;
  }
  .categorie-section-header .categorie-section-title {
    text-wrap: initial;
    margin-bottom: 2rem;
  }
  .categorie-section-text {
    font-size: 1rem;
    margin-top: 1rem;
    text-align: justify;
  }
  .card-container-description {
    text-align: justify;
  }
}
