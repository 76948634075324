.cart-element {
    display: grid;
    grid-template-columns: 40% 60%;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
    margin: 1em 0em;
    padding: 0.5em;
    border-radius: 10px;
    direction: ltr !important;
}
.img-container {
    border-radius: 10px;
    overflow: hidden;
}
.cart-element .cart-element-img{
    width: 100%;
    border-radius: 10px !important;
    object-fit: cover;
}
.cart-element  .cart-element-detail{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.7rem;
}
.cart-element  .cart-element-detail .date-childe{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.flex-column {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.cart-element  p{
    color: #00213B;
    font-size: 1rem;
}
.cart-element  p span{
font-weight: 600;
font-size: 0.9rem;
}
select{
    color: #00213B;
    padding: 0.5em;
    border: 1px solid #e5e6e6c5;
    outline: #00213B;
}
.quantity .incrementor-decrementor{
    display: flex;
    flex-direction: row;
    margin-top: .5rem;
    gap: 1rem
}
.incrementor-decrementor .btn{
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CCB0E0;
    border: none;
    cursor: pointer;
}
.incrementor-decrementor .icon{
    font-weight: bold;
}
.incrementor-decrementor .quantity-value {
    font-weight: bold;
    font-size: 1.3rem;
    text-align: center;
    display: inline-block;
}
.incrementor-decrementor .quantity-delete {
    background-color: transparent;
    color: #ff0000a3;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .cart-element {
        display: flex;
        flex-direction: column;
    }

    .img-container {
        width: 100%;
    }

    .cart-element-detail {
        width: 100%;
    }
}

