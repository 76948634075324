.cookie-consent {
  position: fixed;
  bottom: 20px;
  left: 20px;
  overflow: hidden;
  justify-self: start;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.895);
  backdrop-filter: blur(5px);
  padding: 16px 32px;
  text-align: center;
  margin: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 6;
  /* border: #6ccddc solid 1px; */
}

.cookie-header {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  /* border: red solid; */
}
.cookie-header-title {
  display: flex;
  align-items: baseline;
  gap: 16px;
}
.cookie-icon {
  height: 3rem;
  /* border: orange solid; */
}
.cookie-mentions-legales {
  text-decoration: none;
}
.cookie-mentions-legales-text {
  color: black;
  text-decoration: underline;
  /* text-wrap: nowrap; */
}

.cookie-consent p {
  margin-bottom: 2%;
}

.cookie-footer {
  display: flex;
}

.cookie-primary-button {
  background: linear-gradient(90deg, #b5aee6 10%, #ecb3d8 90%);
  color: #fff;
  border: none;
  padding: 8px 16px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
}

.cookie-primary-button:hover {
  background: #d0a1bf;
}

.cookie-secondary-button {
  background: linear-gradient(90deg, #b6aee686 10%, #ecb3d89f 90%);
  color: rgba(0, 0, 0, 0.7);
  border: none;
  padding: 8px 16px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
}

.cookie-secondary-button:hover {
  background: #d0a1bf;
}

@media only screen and (max-width: 600px) {
  .cookie-consent {
    width: 70%;
    left: 0;
    bottom: 0;
    padding: 8px 48px;
    font-size: 14px;
  }
  .cookie-consent button {
    margin-bottom: 5%;
  }
}
