.intro-section-container {
  background-color: transparent;
  min-width: min-content;
  width: 90%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  gap: 6vh;
  /* border: aqua solid; */
}

.transparent-container {
  border-radius: 5px;
}

@keyframes intro-apparition {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
  }
}

.intro-section-container.visible * {
  animation: intro-apparition 0.5s ease-in-out;
}

.intro-section-header {
  min-width: min-content;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  /* border: green solid; */
}


.intro-section-title {
  text-align: center;
  margin-bottom: 1.5rem;
}
.intro-section-header .intro-title {
  font-size: 3.5rem;
  font-weight: 700;
  color: #00213b;
  white-space: nowrap;
}

.intro-section-text {
  text-align: start;
  text-wrap: balance;
  display:block;
  width: 100%;
}

.intro-section-link {
  color: #00213b;
  text-decoration: none;
}

.intro-section-button {
  width: fit-content;
  font-weight: bold;
  font-size: 20px;
  color: white;
  /* background-color: #faa661; */
  background: linear-gradient(90deg, #b5aee6 10%, #ecb3d8 90%);
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.intro-section-button:hover {
  /* background-color: #236aae; */
  background: linear-gradient(to left, #eaa6a6, #efda66);
}

.intro-section-button-weight {
  font-weight: 600;
}

.intro-section-body {
  padding: 20px 0;
  min-width: min-content;
  width: 70%;
  display: flex;
  gap: 1vw;
  /* border: blue solid; */
}

.intro-section-body-separator {
  border: rgba(220, 220, 220, 0.749) solid 1px;
}

.intro-section-footer {
  min-width: min-content;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  /* border: green solid; */
}

@media (max-width: 800px) {
  .intro-section-body {
    flex-direction: column;
    gap: 4vh;
  }

  .intro-section-body-separator {
    display: none;
  }

  .intro-section-header {
    width: auto;
  }
  .intro-section-text {
    text-align: justify;
    font-size: 1.1rem;
  }
}
