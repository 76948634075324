#root{
    overflow: hidden;
}
.kids-page-atelier-section-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em 4em;
    text-align: center;
    width: 90%;
}

.kids-page-atelier-section-wrapper h2{
    font-weight: 900;
    color: #00213b;
    
}
.kids-page-atelier-section-wrapper p{
    color: gray;

}



.kids-page-atelier-section-list-wrapper{
    margin: 2em 0 4em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}


.kids-page-atelier-section-upper-wrapper{
    isolation: isolate;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3vh;
    padding-bottom: 10vh;
    background-color: #F0F2F8;
}



@media (max-width: 1500px){
    .kids-page-atelier-section-wrapper{
        padding: 0;
    }
    .atelier-card-container-anchor{
        flex: 1;
        width: 100%;
        
    }
    
    .kids-page-atelier-section-wrapper >*:last-child{
        margin-bottom: 0;
    }
}

@media (min-width: 700px){
    .kids-page-atelier-section-wrapper h2{
        font-size: 2.6rem;
    }
}


@media (min-width:1210px){

    .kids-page-atelier-section-list-wrapper{
        gap:2vw;
        align-items: start;
    }

    .atelier-card-container-anchor{
        /* min-height:950px; */
        min-height:900px;
    }
}


