/* Styling cards */

.formation-card-2 {
  background-color: #fff;
  border: none;
  border-radius: 20px;
  width: 95%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 24px;
  padding: 16px 16px 32px 16px;
  /*height: 672px;*/
}

.formation-card-2 h3 {
  margin-top: 0;
  font-size: 22px;
  color: #000;
  text-align: center;
}

.formation-card-2 p {
  font-size: 16px;
  color: #666;
  text-align: start;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: min-content;
  display: block;
}

.formation-card-image-2 {
  min-height: 350px;
  max-height: 350px;
}

.formation-card-buttons-2 {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-evenly;
  width:80%;
  /* margin-top: 24px; */
}
.add-cart-btn {
  border-radius: 20px;
  cursor: pointer;

  padding: 10px 17px;
}
.add-cart-btn.remove {
  border: 1px solid #655f91;
  background: none;
  color: #655f91;
}
.add-cart-btn.add {
  background: linear-gradient(to left, #eaa6a6, #efda66);
  color: #fff;
}



@media (max-width: 320px) {
  .formation-card-button-2 {
  
    flex-direction: column;
  }
}

@media (min-width: 375px) {
  .formation-card-button-2 {
    width: auto;
  }
  .formation-card-buttons-2 {
    flex-wrap: nowrap;
    justify-content: center;
  }
}

.formation-card-button-2 {
  padding: 10px 26px;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  color: #fff;
  background-color: #978ce0;
  cursor: pointer;

  transition: background-color 0.3s ease;
}
.formation-card-link-2 {
  padding: 10px 24px;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  color: #fff;
  background-color: #e4b3da;
  cursor: pointer;

  transition: background-color 0.3s ease;
}
.list-formation-section-2 {
  width: 100%;
}

.formation-card-button-2:hover,
.formation-card-link-2:hover {
  background: #8b81cc;
}

.formation-card-link-2 {
  color: #fff;
  text-decoration: none;
}

.formation-card-image-2 {
  min-width: 150px;
  border-radius: 10px !important;
  object-fit: cover;
  width: 100%;
  min-height: 300px;
  /* height:280px; */
}

@media (max-width: 370px) {
  .formation-card-image-2 {
    min-height: 240px;
    max-height: 240px;
  }
}

@media (min-width: 550px) {
  .formation-card-2 {
    padding: 16px 24px 32px 24px;
  }

  img.formation-card-image-2 {
    min-height: 400px;
    max-height: 400px;
    height: 400px;
  }
}
@media (max-width: 425px) {
  .formation-card-buttons-2 {
    flex-direction: column;
  }
}

@media (min-width: 815px) {
  .formation-card-buttons-2 {
    flex-direction: column;
    justify-content: center;
    /*width: min-content;*/
  }

  .formation-card-2 {
    /* max-width:360px; */
    max-width: 380px;
  }

  img.formation-card-image-2 {
    height: auto;
    /* min-height: 270px;
        max-height: 270px; */

    min-height: 350px;
    max-height: 350px;
  }
}
