.list-formation-wrapper {
  isolation: isolate;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3vh;
  padding-bottom: 10vh;
  text-align: center;
}

.list-formation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
  gap: 3vh;
  width: 100%;
  max-width: 1200px;
}

@media (max-width:550px){
  .list-formation-container{
    margin: 10px;
  }
}

.list-formation-header {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;
}
.list-formation-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}
.list-formation-container .list-formation-title{
  font-size: 1rem;
  font-weight: 700;
  color: #655f91;
}
.list-formation-container .formation-period{
  display: flex;
    justify-content: center;
  align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}
.list-formation-link {
  color: white;
  background-color: #aaa2dc;
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}
.list-formation-link.active {
  background-color: #E4B3DA;
  color: #FFF;
}
.list-formation-link.inactive{
  border: 1px solid #655f91;
  color: #8b81cc;
  background-color: transparent;
}
.list-formation-button {
  color: white;
  background-color: #aaa2dc;
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}

.list-formation-button:hover {
  color: #fff;
}

.list-formation-button.active {
  background-color: #978ce0;
  color: #FFF;
}

.list-formation-body {
  width: clamp(100px, 100%, 1200px);
  display: flex;
  gap: 1vh;
  position: relative;
  z-index: 10;
  width: 100%;

}

.formation-cards-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 1em;
}

.list-formation-introduction {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.list-formation-introduction p {
  margin: 0;
}

.formation-card {
  background-color: #fff;
  border: none;
  border-radius: 20px;
  padding: 1em; 
  margin: 20px; 
  width: 100%; 
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  flex: 1;
}
.btn{
  position: relative;
 
  color: white;
  border: 2px solid #aaa2dc;

 
  overflow: hidden;
  transition: 1s all ease;
}
.btn::before{
  background: #aaa2dc;
  content: "";
  position: absolute;
  top: 50%;
  border-radius: 30px;

  left: 50%;
  transform: translate(-50%,-50%);
  z-index: -1;
  transition: all 0.6s ease;
}

.btn1::before{
  width: 0%;
  height: 100%;
}

.btn1:hover::before{
  width: 100% !important;
}


.inactive{
  border: 1px solid #655f91;
  color: #8b81cc;
  background-color: transparent;
}

.formation-ado-more-modal-wrapper {
  overflow: scroll;
  overflow-x: hidden;
  height: 80vh;
  /* border: purple solid; */
}

.formation-ado-more-modal {
  border: 0;
  border-radius: 6px;
  box-shadow: 0 0 0 1000px #00000080;
  overflow: visible;
  width: clamp(100px,90%,1000px);
}

.ado-formation-details-close-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-content: center; */
  bottom: calc(100% + 4px);
  left: calc(100% - 40px);
  border-radius: 12px;
  color: black;
  background-color: white;
  width: 40px;
  font-size: 30px;
  aspect-ratio: 1/1;
  z-index: 1;
}

.ado-formation-details-close-button:hover {
  background-color: #79c3ee;
  cursor: pointer;
}




.formation-ado-contact-modal-wrapper {
  /* overflow: scroll; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  height: 80vh;
  width: 100%;
  overflow: visible;
  /* border: purple solid; */
}

.formation-ado-contact-modal {
  background-color: #fcfcfc;
  overflow: visible;
  border: 0;
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
  width: 600px;
  border-radius: 6px;
  /* border: red solid; */
}


@media (max-width: 768px) {
  .formation-ado-more-modal {
    width: 85%;
  }
  .formation-ado-more-modal-wrapper {
    height: 85vh;
    width: 100%;
  }
  .ado-formation-details-close-button {
    width: 30px;
    font-size: 20px;
    left: calc(100% - 30px);
    bottom: calc(95%);
  }
  .formation-ado-contact-modal-wrapper {
    height: 83vh;
    height: fit-content;
  }
}



