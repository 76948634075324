.cgu-wrapper {
  padding: 64px 2rem;
  display: flex;
  flex-direction: column;
  align-items:center;
  /* overflow:hidden; */
  /* align-items: center; */
  /* border: aqua solid; */
}


.cgu-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 90%;
  max-width: 1400px;
  /* border: red solid; */
}

.cgu-container a {
  color: #6ccddc;
  text-decoration: underline;
  font-weight:800;
}


.cgu-header {
  width: fit-content;
  text-decoration: underline 3px;
  color : #236aae;
}
.cgu-section-title{
  color: #388ddc;
}
.cgu-section-subtitle{
  color: #388ddc;
}

.subtitle-color {
  color: #388ddc;
}

.cgu-section {
  position: relative;
  /* isolation: isolate; */
}

.cgu-section-link {
  font-weight: bold;
  color: initial;
  text-decoration: none;
}

.cgu-footer {
  width: 100%;
  display:flex;
  justify-content: end;
  align-items:center;
}

.cgu-footer p {
  line-height: 1.2rem;
  font-size: 0.9rem;
}