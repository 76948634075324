.footer-wrapper {
  position: relative;
}

.footer-top1 {
  position: absolute;
  z-index: -1;
  top: -70px;
  width: 100%;
  height: 210px;
  background-color: #e4b3da;
  clip-path: polygon(0 0, 0 32%, 100% 34%);
  z-index: 2;
}

.footer-top2 {
  position: absolute;
  z-index: -1;
  top: -70px;
  width: 100%;
  height: 220px;
  background-color: #b5aee6;
  clip-path: polygon(0 13%, 0 32%, 100% 34%);
  z-index: 2;
}

.footer-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #978ce0;
  padding: 16px;
  padding-top: 32px;
  /* border: red solid; */
  z-index: 4;
}

.footer-transport-icon{
  max-width: 25px;
  object-fit: contain;
}

.footer-body {
  display: flex;
  justify-content: space-around;
  position: relative;
  /* width: 100%; */
}
.footer-body .ebook-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.footer-body .ebook-container p {
    color: white;
    font-size: 1.7rem;
    font-weight: 600;
}
.footer-body .ebook-container h6{
    color: white;
    font-size: 1rem;
    font-weight: 500;
}
.footer-body .ebook-container .ebook-form{
    display: flex;
    flex-direction: column;
    gap: .9rem;
}
.footer-body .ebook-form .ebook-email-container{
    display: flex;
}
.footer-body .ebook-form .ebook-email-container input {
    padding: .8rem .5rem;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1rem;
    outline: none;
}
.footer-body .ebook-form .ebook-email-container button {
  padding: .8rem;
    background-color: #f9a826;
    color: white;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 1rem;
    cursor: pointer;
}
 .btn-telecharge{
    padding: 1rem 3rem;
    background-color: #f9a826;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
   width: max-content;
}
.social-networks-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.social-networks-title {
  /* text-decoration: underline; */
  color: white;
}
.social-networks-icons-container {
  display: flex;
  gap: 16px;
}

.plan-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.plan-title {
  /* text-decoration: underline; */
  color: white;
}
.plan-body {
  position: relative;
  display: flex;
  gap: 64px;
  z-index: 10;
}
.plan-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.plan-link {
  text-decoration: none;
}
.plan-text {
  color: white;
  font-size: 1rem;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
.info-title {
  /* text-decoration: underline; */
  color: white;
}
.info-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  /* border: red solid; */
}
.info-link {
  text-decoration: none;
}
.info-text {
  color: white;
  font-size: 1.2rem;
}

.transport-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
.transport-title {
  /* text-decoration: underline; */
  color: white;
}
.transport-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  /* border: red solid; */
}

.transport-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.transport-images {
  display: flex;
  gap: 16px;
  align-items: center;
}

.transport-link {
  text-decoration: none;
}
.transport-text {
  color: white;
  font-size: 1.2rem;
}

.footer-adress-responsive {
  display: none;
}

.footer-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 200px;
  /* border: red solid; */
}

.logo-footer-image {
  width: 150px;
}
.footer-text {
  color: white;
  font-size: 1.1rem;
}

.accessibility-link-container {
  display: flex;
  margin: 0 auto;
  gap: 0.5rem;
}

.transport-description {
  display: flex;
  gap: 8px;
  align-items: center;
}

@media (max-width: 768px) {
  .footer-container {
    gap: 8px;
  }
  .footer-body {
    flex-direction: column;
    gap: 32px;
  }
  .social-networks-container {
    gap: 8px;
    align-items: center;
  }
  .social-networks-title {
    /* text-decoration: underli ne; */
    font-size: 1.8rem;
  }
  .social-networks-icons-container {
    width: 100%;
    justify-content: space-around;
  }

  .plan-container {
    gap: 8px;
    align-items: center;
  }
  .info-body .plan-container .info-link-plan {
    font-size: 1.72rem;
    color: white;
    font-weight: 600;
    margin-top: .5rem;
  }
  .plan-title {
    /* text-decoration: underline; */
    font-size: 1.8rem;
  }
  .plan-body {
    width: 100%;
    justify-content: space-around;
    z-index: 1;
  }

  .info-container {
    gap: 8px;
    align-items: center;
  }
  .info-title {
    /* text-decoration: underline; */
    font-size: 1.8rem;
  }

  .info-body {
    width: 85%;
    flex-direction: row;
    flex-wrap: wrap;
    height: 80px;
    z-index: 1;
    max-width: 350px;
    align-items: center;
    justify-content: space-between;
    justify-content: center;
    column-gap: 1rem;
  }

  .transport-container {
    align-items: center;
  }
  .transport-title {
    font-size: 1.8rem;
  }

  .transport-section {
    align-items: center;
  }
  .transport-description {
    display: flex;
    gap: 8px;
    text-align: center;
    width: 100%;
  }

  .footer-footer {
    padding: 8px 16px;
    /* border: blue solid; */
  }

  .footer-adress {
    display: none;
  }

  .footer-adress-responsive {
    margin: 8px 0 0 0;
    text-align: center;
    display: block;
  }
  .logo-footer-image {
    width: 100px;
  }
}

@media (max-width: 550px) {
  .info-body {
    width: 95%;
    flex-direction: row;
    flex-wrap: wrap;
    height: fit-content;
    z-index: 1;
    align-items: center;
    justify-content: center;
    /* border: blue solid; */
  }

  #contact-section-footer-link {
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .footer-footer {
    padding: 8px 16px;
    justify-content: space-around;
  }

  .footer-adress {
    /* text-wrap: balance; */
    width: 40%;
    /* border: blue solid; */
  }
}

.info-body .plan-container .info-link-plan {
  font-size:  1.72rem;
  color: white;
  font-weight: 600;
  margin-top: 1rem;
}
.message-container{
  padding: .5rem;
  background-color: #E4B3DA;
  margin-top: .5rem;
  border-radius: 7px;
  transition: opacity 0.8s ease-out;
  opacity: 0;
}
.message-container .message{
  color: #5a4da3;
  font-size: .8rem;
  display: block;
}
.message-container.show {
  opacity: 1;
  max-width: 85%;
}
.message-container.hide {
  opacity: 0;
}