.ado-page-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    background-color: #f0f2f8;
    /* border: red solid; */
  }
  
.list-formation-section {
  width: 100%;
}