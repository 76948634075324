.faq-section-wrapper {
  isolation: isolate;
  position: relative;
  background-color: white;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6vh 0;
  gap: 2vh;
  /* border: aqua solid; */
}

.faq-section-container {
  position: relative;
  top: -100px;
  background-color: #edf1f7;
  border-radius: 15px;
  padding: 4vw;
  width: 80%;
  min-width: min-content;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  gap: 3vh;
  /* border: greenyellow solid; */
}

.faq-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-section-title {
  text-align: center;
}

.faq-section-body {
  width: clamp(300px, 85%, 800px);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 5px; */
  /* border: blue solid; */
}

.faq-response {
  padding: 12px;
}

.faq-link {
  text-wrap: nowrap;
  color: black;
  font-weight: bold;
  text-decoration: none;
}
