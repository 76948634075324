.atelier-card-container-anchor .atelier-card-botton{
    /*font-size: 1.2rem;*/
    /*color: white;*/
    /*background: linear-gradient(90deg, #b5aee6 10%, #ecb3d8 90%);*/
    /*border-radius: 50px;*/
    /*cursor: pointer;*/
    /*padding: 1em 2em;*/
    padding: 1rem 2rem;
    border-radius: 50px;
    border: none;
    text-decoration: none;
    color: #fff;
    background-color: #E4B3DA;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1.2rem;
}
.atelier-card-container-anchor .btn-wrapper .add-cart-btn {
    padding: 1rem 2rem;
    border-radius: 50px;
    border: none;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: linear-gradient(to left, #eaa6a6, #efda66);
    font-size: 1.2rem;
}
.atelier-card-container-anchor .btn-wrapper .add-cart-btn.remove{
    border: 1px solid #655f91;
    background: none;
    color: #655f91;
}
.atelier-card-container-anchor .btn-wrapper .add-cart-btn.add{
    background: linear-gradient(to left, #eaa6a6, #efda66);
    color: #fff;
}
.atelier-card-botton:hover {
    background: linear-gradient(to left, #eaa6a6, #efda66);
}
.atelier-card-container-anchor{
    position: relative;
    margin: 0.5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;
    max-width: 550px;
    background-color: #fff;
    text-decoration: none;
    color: #00213b;
    min-width:300px ;
    flex: 1;
    border-radius: 10px;
    z-index: 2;
    overflow: hidden;
}
.label2{
    display: flex;
    position: relative;
}
.hide{
    display: none;
}
.label2 img{
    max-width: 90px !important;
    width: 100%;
}
.label2 > span{
    position: absolute;
    top: -10%;
    left: 0;
    right: 0;
    color: #fff;
    font-weight: bold;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9rem;
}
.atelier-card-container-anchor > div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.atelier-card-container-anchor .btn-wrapper{
    padding-bottom: 2em;
    margin-top:2rem;
    display: flex;
    justify-content: space-around;
}
.atelier-card-container-anchor h3{
    font-size: 1.8rem ;
    font-weight: bold;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}
.atelier-card-container-anchor p{
    font-size: 1rem;
    margin-top: 1em;
    text-align: start;
}
.atelier-card-container-anchor img{
    width: 100%;
    margin: auto;
    flex: 1;
    max-width: 520px;
    border-radius: 10px;
}

@media (max-width: 1500px){
    .atelier-card-container-anchor{
        margin:1em 0  5em ;
    }

}

@media (min-width: 700px){
    .atelier-card-container-anchor h3{
        font-size: 2rem;
    }
    .atelier-card-container-anchor p{
        font-size: 1.2rem ;
    }

    .atelier-card-container-anchor > div button{
        font-size: 1.6rem;
    }


}
@media (max-width: 1024px) {
    .atelier-card-container-anchor .btn-wrapper{
        flex-direction: column;
        gap: 1rem;
    }
    .atelier-card-container-anchor .btn-wrapper .atelier-card-botton,
    .atelier-card-container-anchor .btn-wrapper .add-cart-btn {
        width: 100%;
    }
}

@media (min-width: 1700px)
{

    .atelier-card-container-anchor h3{
        font-size: 2.5rem;
    }

    .atelier-card-container-anchor{
        max-width: 35vw;
    }
}
.formation-kids-more-modal{
    overflow: visible;
    border: 0;
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
    width: clamp(100px, 90%, 1000px);
    border-radius: 6px;
}

.formation-kids-more-modal-wrapper{
    overflow: scroll;
    overflow-x: hidden;
    height: 80vh;
}
.kids-formation-details-close-button{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: calc(100% + 4px);
    left: calc(100% - 40px);
    z-index: 1;
    border-radius: 12px;
    color: black;
    background-color: white;
    width: 40px;
    font-size: 30px;
    aspect-ratio: 1/1;
}
.kids-formation-details-close-button:hover{
    /* background-color: #79c3ee; */
    background-color: #978ce0;
    cursor: pointer;
}
.formation-kids-contact-modal-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    height: 80vh;
    width: 100%;
    overflow: visible;
}
.formation-kids-contact-modal{
    background-color: #fcfcfc;
    overflow: visible;
    border: 0;
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
    width: 600px;
    border-radius: 6px;
}