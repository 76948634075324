.ado-hero-section-wrapper {
  position: relative;
  width: 100vw;
  height: 90vh;
  background-position: center;
  gap: 8px;
  color: white;
}

.ado-hero-section-wrapper > * {
  margin: 0;
}

.ado-hero-section-background {
  width: 200%;
  background-size: cover;
}

.ado-hero-section-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 8px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* border: orange solid; */
}
.ado-hero-section-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 1vh;
  padding-bottom: 80px;
}

@keyframes ado-hero-apparition {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    transform: translateY(0);
  }
}

.ado-hero-section-title {
  color: white;
  line-height: 90px;
  text-align: center;
  font-size: 70px;
}

.ado-hero-section-container.visible * {
  animation: ado-hero-apparition 0.5s ease-in-out;
}

.ado-hero-section-cta {
  font-weight: bold;
  font-size: 20px;
  color: white;
  /* background-color: #faa661; */
  background: linear-gradient(to right, #b5aee6, #ecb3d8);
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.ado-hero-section-cta:hover {
  /* color: black; */
  background: linear-gradient(to left, #eaa6a6, #efda66);
}

.ado-hero-section-cta-logo {
  border-radius: 50px;
  font-size: small;
}

.ado-hero-section-wrapper {
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .ado-hero-section-container {
    align-items: center;
  }
  .ado-hero-section-body {
    padding-bottom: 0px;
  }
  .ado-hero-section-title {
    line-height: 70px;
    font-size: 60px;
  }
  .ado-hero-section-slogan {
    font-size: 22px;
  }
}
