.tabs-header {
    display: flex;
    justify-content: start;
    width: min-content;
    background-color: #f0f2f8;
    border-radius: 5px;
    color: slategray;
    font-weight: 500;
    height: 2.5rem;
  }
  
  .tab-header-item {
    padding: 0.4rem 0.9rem;
    margin: 4px 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    text-wrap: nowrap;
  }
  
  .tab-header-item.active {
    color: #000;
    border-radius: 9px;
    background-color: white;
  }
  
  .sidebar {
    grid-row: span 1;
    background-color: #f0f2f8;
    padding: 1rem;
    min-width: 150px;
    height: fit-content;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: start;
    align-self: start;
  }
  .sidebar-header p {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .sidebar-content ul {
    padding: 0;
    list-style-type: none;
    color: slategray;
    font-size: large;
  }
  .sidebar-content ul li {
    margin: 0.5rem 0;
    cursor: pointer;
  }
  
  .sidebar-content ul a {
    text-decoration: none;
    font: inherit;
    color: inherit;
  }
  
  .legal-notices-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tabs {
    display: flex;
    flex-direction: column;
    width:100%;
    min-height: 100dvh;
    padding-top: 90px;
    z-index: 1;
  }
  
  .tab-header-item {
    margin: 6px 8px;
  }
  
  .tab-content {
    width: 100%;
    overflow: hidden;
  }
  
  .legal-notices-logo-container {
    grid-column: 6/7;
    grid-row: 1/1;
    max-width: 60px;
    padding-left: 1rem;
    background-color: white;
  }
  
  .legal-notices-logo-container a {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .logo-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    cursor: pointer;
  }
  
  @media (max-width: 550px) {
    .tabs {
      max-width: 100dvw;
      overflow: hidden;
    }
  
    .legal-notices-logo-container {
      display: none;
    }
  
    .tabs-header {
      box-sizing: border-box;
      padding: 2rem 2rem 0 2rem;
      width: 100%;
      flex-direction: column;
      height: fit-content;
    }
  
    .tab-header-item {
      margin: 4px 8px;
      font-size: 1.1rem;
    }
  
    .sidebar {
      box-sizing: border-box;
      padding: 0 2rem 2rem 2rem;
    }
  
    .sidebar-header {
      margin: 6px 8px;
      padding: 0.4rem 0.9rem 0 0.9rem;
    }
    .sidebar-content {
      margin: 6px 8px;
      padding: 0.4rem 0.9rem;
    }
  
    .sidebar-content ul {
      margin: 0;
    }
  
    .sidebar-content ul a {
      font-size: 1.1rem;
    }
  }
  
  @media (min-width: 550px) {
    .tabs {
      display: grid;
      grid-template-columns: 1fr 6fr;
      grid-template-rows: 1fr auto;
    }
  
    .sidebar {
      grid-column: 1/1;
      grid-row: 1/3;
      min-height: 100%;
      position: relative;
      z-index: 0;
    }
  
    .tabs-header {
      grid-column: 2/6;
      grid-row: span 1;
      margin: 2rem 2rem;
    }
  
    .tab-content {
      grid-column: 2/7;
      grid-row: 2/3;
    }
  
    .sidebar-header {
      position: sticky;
      top: 0rem;
      padding: 1rem 0;
      background-color: #f0f2f8;
      z-index: 1;
    }
  
    .sidebar-content {
      position: sticky;
      top: 3.8rem;
      padding-bottom: 80px;
    }
  }
  
  @media (min-width: 550px) and (max-width: 850px) {
    .tabs {
      grid-template-columns: 1fr 5fr;
    }
  }

  @media screen and (max-width:760px){
    .navbar{
      position: fixed;
    }
  }
  
  
  @media (min-width: 850px) {
    .legal-notices-logo-container {
      display: inline;
      position: absolute;
      top: calc(1rem+70px);
      right: 1rem;
      width: 80px;
      max-width: none;
    }
  }
  
  @media (min-width: 980px) {
    .legal-notices-logo-container {
      width: 200px;
    }
  }
  
  @media (min-width: 1550px) {
    .legal-notices-logo-container {
      width: calc(200px + 2vh);
    }
  }
  