.card-container-anchor {
  flex: 1;
  text-decoration: none;
  padding: 16px;
  display: grid;
  grid-template-columns: auto;
  row-gap: 3vh;
  text-align: left;
  border: #baaee5 solid 1px;
  border-radius: 8px;
  background-color: #baaee514;
  transition: all 0.3s ease-in-out;
}

.card-container-anchor:hover {
  scale: 1.05;
  background-color: #baaee54a;
}

.card-container-image {
  aspect-ratio: 3/2;
  object-fit: cover;
  border-radius: 4px;
}

.card-container-image:hover {
  box-shadow: green 5px 5px 5px 300px inset;
}

.card-container-title {
  color: #0d003a;
  align-self: end;
  margin-bottom: 16px;
  /* border: blue solid; */
  text-align: center;
  /* width: fit-content; */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
}




.card-container-description {
  color: black;
  /* text-wrap: balance; */
  text-align: start;
}

@media (max-width: 768px) {
  .card-container-anchor {
    align-items: center;
  }

  .card-container-description {
    text-align: center;
  }
}
