/*************************************************/

.hacienda-section-container {
  width: 67%;
  min-width: min-content;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  gap: 2vw;
  /* border: greenyellow solid; */
}

@keyframes hacienda-apparition {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
  }
}

.hacienda-section-container.visible > * {
  animation: hacienda-apparition 0.5s ease-in-out;
}

.hacienda-section-description {
  min-width: 300px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4vh;
  align-items: center;
  /* border:red solid */
}
.hacienda-section-body-container {
  padding: 8px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.hacienda-section-body-text {
  padding: 8px;
}

.hacienda-section-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2vh;
 
  
  
  
}

/*.hacienda-section-title {
  text-wrap: nowrap;
  white-space: nowrap;
}*/

.hacienda-section-footer {
  display: flex;
  gap: 16px;
}

.hacienda-section-button {
  text-decoration: none;
  width: fit-content;
  position: relative;
  color: #236aae;
}
.hacienda-section-button:hover {
  color: #25aabe;
}

.hacienda-section-button:before {
  content: "";
  border-bottom: 1px solid #25aabe;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  transition: 0.3s;
}

.hacienda-section-button:hover:before {
  width: 100%;
  padding: 0;
}

.kid-section-illustration {
  position: relative;
  min-width: 300px;
  max-height: 50vw;
  flex: 1;
  aspect-ratio: 1/1;
  /* border: red solid; */
}

.kid-section-image {
  width: 100%;
  /* height: 100%; */
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 5px;
  /* border: purple solid; */
}

.kid-section-container-circle {
  position: absolute;
  top: 64px;
  right: -20%;
  width: 50%;
  /* height: 50%; */
  aspect-ratio: 1/1;
  border-radius: 100%;
  border: #e7d5f8 solid 8px;
  overflow: hidden;
}

.kid-section-image-circle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  /* border: purple solid; */
}
.hacienda-section-header .hacienda-title {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .hacienda-section-footer {
    flex-direction: column;
  }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Your styles for extra small devices */
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Your styles for small devices */
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991.98px) {
  /* Your styles for medium devices */
  .hacienda-section-header .hacienda-title {
    font-size: 2.7rem;
    font-weight: 700;
    color: #00213b;
    white-space: nowrap;
    text-align: center;
  }
  .hacienda-section-title {
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
  .hacienda-section-header .hacienda-text {
    text-align: justify;
    font-size: 1.2rem;
  }
  .hacienda-section-description .hacienda-section-button-weight {
    white-space: nowrap;
    padding: 0.8rem 1rem;
  }
  .hacienda-section-description .margin {
    margin: 1.5rem 0;
  }
  .hacienda-section-body {
    margin-bottom: 1.2rem;
  }
  .hacienda-section-body-text {
    font-size: 1.2rem;
    text-align: justify;
  }
  .hacienda-section-body-container .hero-section-button-weight.white-space {
    white-space: nowrap;
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
  .kid-section-illustration .kid-section-container-circle {
    display: none;
  }
  .kid-section-illustration {
    width: 90%;
  }
  .kid-section-illustration .kid-section-image {
    border-radius: 7px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Your styles for large devices */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Your styles for extra large devices */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {

  /* Your styles for extra extra large devices */
}
