.carts-empty-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.carts-empty-container .cart-text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    height: 40rem;
    width: 60%;
    margin: auto;
    padding-top: 1rem;
    color: #00213B;
    border: 1px solid #ebe0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 -4px 8px rgba(0, 0, 0, 0.05), 4px 0 8px rgba(0, 0, 0, 0.05), -4px 0 8px rgba(0, 0, 0, 0.05);

}
.carts-empty-container .cart-empty-img {
    width: 60%;
    height: 22rem;
}
.carts-empty-container .cart-empty-link{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    text-decoration: none;
    color: #00213B;
    font-weight: 600;
}
.carts-empty-container .cart-text .cart-empty-text {
    font-size: 1rem;
    color: #00213ba6;
    padding:0 1rem;
}
.carts-empty-container .cart-text .no-lettre-spacing{
    letter-spacing: 0;
    font-size: 1rem;
    color: #00213ba6;
    padding: 0 1rem;
}

@keyframes moveRightToLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-5px);
    }
}

.cart-empty-link .icon {
    display: inline-block;
    animation: moveRightToLeft 0.7s infinite alternate;
}

@media (max-width: 768px) and (max-width: 1024px) {
    .carts-empty-container .cart-text {
        width: 90%;
    }
    .carts-empty-container .cart-text .cart-empty-text {
        text-align: center;
    }
    .carts-empty-container .cart-empty-img{
        width: 90%;
    }
}

.centre{
    max-width: 80%;
    text-align: center;
}

