.cart{
    display: flex;
    min-height: 100vh;
    margin-top: 6%;
    padding:0.5em;
    justify-content: space-between;
    isolation: isolate;
    position: relative;
}
.cart-wrapper {
    isolation: isolate;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-top: 100px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10rem;
    position: relative;
    background-color: transparent;
    width: 70%;
    max-width: 1400px;
}
.cart-wrapper .cart-container {
    width: 100%;
    padding: 2rem;
}
.cart-wrapper .cart-container .cart-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 100%;
    height: 100%;
}

.cart-wrapper >* {
    padding: 1em 0.5em;
}
.cart-wrapper >* h2{
    font-size: 2rem;
    margin-bottom: 1em;
}
.cart-wrapper .panier{
    grid-column: span 2;
    border-radius: 10px;
    background-color: rgba(244, 244, 244, 0.77);
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 -4px 8px rgba(0, 0, 0, 0.05), 4px 0 8px rgba(0, 0, 0, 0.05), -4px 0 8px rgba(0, 0, 0, 0.05);
    min-height: 80vh;
    display: flex;
    flex-direction: column;
}
.push-to-bottom {
    margin-top: auto;
}
.cart-wrapper .panier .link{
    display: flex;
    align-items: center;
    gap: .5rem;
    text-decoration: none;
    color: #00213B;
    font-weight: 600;
}
.cart-wrapper .panier .link .icon-add {
    display: inline-block;
    animation: moveRightToLeft 0.7s infinite alternate;
}
.cart-wrapper .panier .link .icon {
    display: inline-block;
    animation: moveRightToLeft 0.7s infinite alternate;
}
.cart-wrapper .total{
    grid-column: span 1;
}
.cart-wrapper .total >div{
    background-color:#f4f4f4b6;
}
.cart-wrapper .elements-cart-container{
    display: flex;
    flex-direction: column;
    gap: 1.3em;
    margin-bottom: 2em;
}
.cart-wrapper .total >div{
    display: flex;
    flex-direction: column;
    height:max-content;
    padding:1em;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
}
.cart-wrapper .total .sum {
    font-weight: bold;
}
.cart-wrapper .total >div h3 {
    font-size: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0.5em 0;
    padding-bottom: 1em;
    border-bottom: 1px solid #BEBEBE;
}

.cart-wrapper .total >div >div{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9rem;
    margin: 1em 0;
    gap: 0.8em;
}

.cart-wrapper .total >div >div input{
    width: 15px;
    height: 15px;
}
.cart-wrapper .total >div h3 span{
    margin-left: auto;
}

.cart-wrapper .total .btn{
    padding: 0.7em  1em;
    width: 100%;
    border-radius: 5px;
    text-decoration: none;
    color: #fff;
    background: linear-gradient(to left, #eaa6a6, #efda66);
    text-align: center;
    margin: 2em 0;

}
.cart-wrapper .total >div img{
    object-fit: contain;
    max-width: 150px;
    margin: auto;
}
.cart-wrapper .total >div p{
    font-weight: bold;
    font-size: 1.2rem;
    color: #00213B;
}
.total .btn {
    padding: 1.2rem 1.2rem;
    width: 100%;
    border-radius: 5px;
    color: #fff;
    background: linear-gradient(to left, #eaa6a6, #efda66);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
    border: none;
    cursor: pointer;
}
.total .center-paypal{
    text-align: center;
}

.total .disabled-btn {
    background: linear-gradient(to left, #eaa6a6, #efda66);
    cursor: not-allowed;
    color: #999;
}

.hover-container {
    position: relative;
    display: inline-block;
}

.hover-container .hover-message {
    visibility: hidden;
    width: 100%;
    background-color: #f8d7da;
    color: #721c24;
    text-align: center;
    padding: 0.5rem;
    border-radius: 5px;
    position: absolute;
    top: -10px;
    left: 0;
    font-size: 0.9rem;
    z-index: 1;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.hover-container:hover .hover-message {
    visibility: visible;
}
.cart-empty-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.cart-empty-container .cart-text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    height: 30rem;
    width: 50%;
    margin: auto;
    padding-top: 1rem;
    color: #00213B;
    border: 1px solid #ebe0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 -4px 8px rgba(0, 0, 0, 0.05), 4px 0 8px rgba(0, 0, 0, 0.05), -4px 0 8px rgba(0, 0, 0, 0.05);
    background-color: #FAFAFA;
}
.cart-empty-container .cart-text .image-container {
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    border: 3px dotted #978CE0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cart-empty-container .cart-empty-img {
    width: 16rem;
    height: 12rem;
}
.cart-empty-container .cart-empty-link{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    text-decoration: none;
    color: #00213B;
    font-weight: 600;
}
.cart-empty-container .cart-text .cart-empty-text {
    font-size: 1rem;
    color: #00213ba6;
    padding:0 1rem;
}
@keyframes moveRightToLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-5px);
    }
}

.cart-empty-link .icon {
    display: inline-block;
    animation: moveRightToLeft 0.7s infinite alternate;
}
@media (max-width: 768px) and (max-width: 1024px) {
    .cart-empty-container .cart-text {
        width: 90%;
    }
    .cart-empty-container .cart-text .cart-empty-text {
        text-align: center;
    }
}


/*Media*/
@media (max-width: 1024px) {
    .cart-wrapper .cart-container .cart-grid  {
        grid-template-columns: 1fr;
        gap: 3rem
    }
    .cart-wrapper .cart-container .cart-grid > * {
        grid-column: span 1;
    }
}

@media (max-width: 1200px) {
    .cart {
        width: 100%;
    }
    .cart-wrapper {
        width: 100%;
        margin-bottom: 0;
        padding-top: 2rem;
    }
    .cart-wrapper .cart-container {
        padding: 1rem;
    }
    .cart-wrapper .panier {
        padding: 1rem;
    }
    .cart-element .cart-element-detail {
        flex-direction: column;
    }
    select {
        max-width: 50%;
    }
}
@media (max-width: 768px) {
    .cart-element-detail .date-childe {
        flex-direction: column;
        gap: 1rem;
        margin-top: .5rem;
    }
    .flex-column {
        gap: 0.5rem;
    }
    .cart-wrapper .panier {
        padding: 1rem
    }
}
.cart-wrapper .total Link.cart-mection-legal {
    background-color: transparent !important;
    width: 100px;
}
@media (max-width:420px) {
    .cart-empty-container .cart-text .image-container {
        width: 15rem;
        height: 15rem;
    }
}