.kid-grid-wrapper{
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    flex-wrap: nowrap !important;
    width: 100%;
    align-items: center;
    height: 400px;
}
img{
    /*border-radius: 0 !important;*/
    object-fit: cover;
    height: 100%;
}
.kid-grid-wrapper > img{
    width: 40%;
    height: 100%;
    flex: 1;
}
.kid-grid-wrapper > div{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 60%;
    height: 100%;
}


.kid-grid-wrapper > div > img{
    width: 100%;
    height: 45%;
}


.kid-grid-wrapper > div > div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: 45%;
    gap: 0.5em;
}
.kid-grid-wrapper > div > div >*{
    flex: 1;
    width: 45%;
    margin: 0 !important;
    
}

@media (max-width: 700px){
    .kid-grid-wrapper > img{
        display: none;

    }
    .kid-grid-wrapper{
        height: 460px;
    }
    .kid-grid-wrapper > div{
        width: 100%;
    }
}

@media (max-width: 350px){
    .kid-grid-wrapper{
        height: 400px;
    }

}