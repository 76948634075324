.play-icon {
  border-radius: 50px;
  transition: 0.3s;
}

.play-icon:hover {
  border-radius: 50px;
  background-color: white;
}

.play-icon:hover > * {
  fill: #25AABE;
}
